export function getStarter(number = Math.floor(Math.random() * 11)) {
  const convoStarters = [
    "Hi there! I'm here whenever you're ready. What's been on your mind lately?",
    "Welcome! If there's something weighing on you or you'd just like to chat, I'm here to listen.",
    "Hey, glad you're here! What's been feeling important or challenging for you today?",
    "Hello! This space is yours. Is there something you'd like to talk through or explore right now?",
    "Hi! Sometimes sharing even the smallest thought can help. What's coming up for you today?",
    "Hey there! I'm glad you're taking a moment for yourself. What's on your mind or heart right now?",
    "Hello! Take your time. Whenever you're ready, tell me what's been on your mind lately.",
    "Hi! Life can feel complicated—I'm here to help untangle things. What's something you'd like to discuss today?",
    "Hey! What's one thing you'd feel better talking about today?",
    "Hello! It's good you're reaching out. What's the first thought or feeling you'd like to share today?",
    "Hi! I'm here to help you explore your thoughts without judgment. What's been coming up for you lately?"
  ];

  return convoStarters[number];
};